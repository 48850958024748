<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>PO No</th>
          <th>PO Date</th>
          <th>Supplier</th>
          <th>Current Step</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in poList.data" :key="i">
          <td>{{ item.po_number }}</td>
          <td>{{ item.date }}</td>
          <td>{{ item.contact_profile?.full_name }}</td>
          <td>{{ item.current_step_no}}</td>
          <td>{{ item.status }}</td>
          <td>
            <button class="btn btn-primary btn-sm"
                    @click="goToReview(item, item.requisition_id)"
                    style="cursor: pointer; margin-left: 10px"
                    v-if="item.status !== 'approved'"
                    :disabled="item.status === 'rejected'"
            >
              Review
            </button>
            <button class="btn btn-primary btn-sm"
                    :disabled="saveButtonLoader"
                    @click="goToReview(item, item.requisition_id, true)"
                    style="cursor: pointer; margin-left: 10px"
                    v-if="!['pending', 'returned'].includes(item.status)"
            >
              View
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <p v-if="!poList.total" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
import {useRoute, useRouter}      from "vue-router";
import {defineEmits, inject, ref} from "vue";
import handleRequisitions         from "@/services/modules/procurement/requisition";

const props  = defineProps({
  poList: Object,
  workflowDetails: Object,
  approvalPageId: {
    type: String,
    default: null
  },
});
const route  = useRoute();
const router = useRouter();
const $emit = defineEmits(['statusUpdated']);
const showError   = inject('showError');
const showSuccess = inject('showSuccess');
const {updatePOStatus} = handleRequisitions();
const saveButtonLoader = ref(false)

const routeList = {
  cs_po: 'po-review',
  pr_po: 'pr-po-approval-view',
  new_po: 'new-po-approval-view'
}

const goToReview = (item, requisition_id, viewOnly = false) => {
  let param = {
    companyId: route.params.companyId,
    moduleId : route.params.moduleId,
    menuId   : route.params.menuId,
    pageId   : route.params.pageId,
  }

  if(! routeList.hasOwnProperty(item.po_type)) {
    showError("Expected purchase order type not found")
    return;
  }

  let routeName = routeList[item.po_type];
  if(viewOnly) {
    routeName = 'po-view'
  }

  let query = route.query;
  query.poId = item.id
  query.approval_page_id = props.approvalPageId;

  router.push({
    name: routeName,
    params: param,
    query: query
  })
}
</script>
